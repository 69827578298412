
    import _debounce from 'lodash/debounce';

    export default {
        data() {
            return {
                visible: false,
                searchQuery: '',
                blogs: [],
                loading: false,
                page: 1, // Pagination
                hasMore: true, // Track if more blogs are available
            };
        },
        methods: {
            showModal() {
                this.visible = true;
                this.$nextTick(() => {
                    const container = this.$refs.scrollContainer;
                    if (container) {
                        container.addEventListener('scroll', this.handleScroll);
                    }
                });
                this.debouncedSearch = _debounce(this.fetchBlogs, 200);
            },
            closeModal() {
                this.visible = false;
                this.searchQuery = '';
                this.blogs = [];
                this.page = 1;
                this.hasMore = true;
            },
            goToBlog(slug) {
                this.closeModal(); // Close the modal
                this.$router.push(`${slug}`); // Navigate to the blog page
            },
            async fetchBlogs(reset = false) {
                if (!this.searchQuery.trim()) return;
                this.loading = true;
                if (reset) {
                    this.page = 1;
                    this.hasMore = true;
                    this.blogs = [];
                }

                try {
                    const { data: { data: { pages } } } = await this.$axios.get(`/u/pages?searchKey=${this.searchQuery}&origin=benhvien16a.com`);
                    this.blogs = pages;

                    if (pages.length === 0) {
                        this.hasMore = false;
                    } else {
                        this.blogs = [...this.blogs, ...pages];
                        // eslint-disable-next-line no-plusplus
                        this.page++;
                    }
                } catch (error) {
                    console.error('Error fetching blogs:', error);
                } finally {
                    this.loading = false;
                }
            },
            handleScroll() {
                const container = this.$refs.scrollContainer;
                if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
                    this.fetchBlogs();
                }
            },
        },
    };
  