
    import { mapState } from 'vuex';
    import TheHeader from '@/components/layout/TheHeader.vue';
    import TheFooter from '@/components/layout/TheFooter.vue';
    import TheDialog from '@/components/layout/Dialog.vue';

    export default {
        components: {
            TheHeader,
            TheFooter,
            TheDialog,
        },

        data() {
            return {
                isScroll: false,
                isShowRightNav: true,
                autoShowDialog: null,
                mountShowDialog: null,
            };
        },
        computed: {
            ...mapState('settings', ['setting']),
        },

        created() {
            window.addEventListener('scroll', this.handleScroll, true);
        },

        mounted() {
            const inforUser = localStorage.getItem('inforUser');
            if (!inforUser) {
                this.openTheDialog();
                this.mountShowDialog = setTimeout(() => {
                    this.$refs.TheDialog.open();
                    clearTimeout(this.mountShowDialog);
                }, 10000);
            }
        },

        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll, true);
            const inforUser = localStorage.getItem('inforUser');
            if (!inforUser) {
                clearInterval(this.autoShowDialog);
            }
        },

        methods: {
            handleScroll() {
                if (window.scrollY > 200) {
                    this.isScroll = true;
                } else {
                    this.isScroll = false;
                }
            },

            openTheDialog() {
                this.autoShowDialog = setInterval(() => {
                    const inforUser = localStorage.getItem('inforUser');
                    if (!inforUser) {
                        this.$refs.TheDialog.open();
                    } else {
                        clearInterval(this.autoShowDialog);
                    }
                }, 45000);
            },

            saveInfor(form) {
                localStorage.setItem('inforUser', JSON.stringify(form));
            },
        },
    };
